// import React, { useState } from 'react';
// import { useSpring, animated } from '@react-spring/web';
// import '../styles/Services.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import Counter from './Counter';
// import { useNavigate } from 'react-router-dom';

// const Services = () => {
//   const navigate = useNavigate();
  // const [visibleCount, setVisibleCount] = useState(3);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // const [isExpanded, setIsExpanded] = useState(false);

  // const showMore = () => {
  //   setVisibleCount(services.length);
  //   setIsExpanded(true);
  // };

  // const showLess = () => {
  //   setVisibleCount(3);
  //   setIsExpanded(false);
  // };
//   const handleServiceClick = () => {
//     navigate('/services');
//   };


//   const services = [
//     { img: require('../assets/horiz1.png'), img1: require('../assets/line1.png'), title: 'Logo Design', text: "Type in a few business details, add some personal preferences and styles and our highly-technologized logo maker will come up with just the right design for you!" },
//     { img: require('../assets/horiz2.png'), img1: require('../assets/line1.png'), title: 'Web Design', text: "Looking to build a stunning online presence? Our all-in-one platform is all you need to elevate yourdigital visibility and online business growth." },
//     { img: require('../assets/horiz3.png'), img1: require('../assets/line1.png'), title: 'Graphic Design', text: "Transform your brand with our expert graphic design services. We create stunning visuals tailored to your vision, ensuring your message shines." },
//     { img: require('../assets/horiz4.png'), img1: require('../assets/line1.png'), title: 'Digital Marketing', text: "Social media is a surefire way to generate more traffic. From increased Return on Investment to amplified conversions." },
//     { img: require('../assets/horiz5.png'), img1: require('../assets/line1.png'), title: 'Mobile App Design', text: "At Pixalogic, we provide all-inclusive mobile app services, regardless of the type of platform and device." },
//     { img: require('../assets/horiz6.png'), img1: require('../assets/line1.png'), title: 'UI / UX Design', text: "Elevate your digital presence with our exceptional UI/UX services, designed to create intuitive and engaging user experiences." },
//   ];


//   const nextSlide = () => {
//     const maxIndex = Math.ceil(services.length ) - 1;
//     if (currentIndex < maxIndex) {
//       setCurrentIndex(currentIndex + 1);
      
//     }
//   };
  
//   const prevSlide = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex(currentIndex - 1);
//     }
//   };
  
//   const totalSlides = Math.ceil(services.length / visibleCount);
//   const translateX = -(currentIndex * 200) / totalSlides;
//   const slideSpring = useSpring({
//     transform: `translateX(${translateX}%)`,
//     config: { tension: 100, friction: 7 },
//   });


//   // const slideSpring = useSpring({
//   //   transform: `translateX(-${currentIndex * (100 / 3)}%)`,
//   //   config: { tension: 100, friction: 7 },
//   // });
//   // const nextSlide = () => {
//   //   if (currentIndex < services.length - visibleCount) {
//   //     setCurrentIndex(currentIndex + visibleCount);
//   //   }
//   // };

//   // const prevSlide = () => {
//   //   if (currentIndex > 0) {
//   //     setCurrentIndex(currentIndex - visibleCount);
//   //   }
//   // };

//   // const slideSpring = useSpring({
//   //   transform: `translateX(-${(currentIndex / services.length) * 100}%)`,
//   //   config: { tension: 100, friction: 7 },
//   // });
//   // const nextSlide = () => {
//   //   // Calculate max index to prevent over-sliding
//   //   const maxIndex = Math.max(services.length - visibleCount, 0);
//   //   if (currentIndex < maxIndex) {
//   //     setCurrentIndex(currentIndex + 1);
//   //   }
//   // };

//   // const prevSlide = () => {
//   //   if (currentIndex > 0) {
//   //     setCurrentIndex(currentIndex - 1);
//   //   }
//   // };

//   // const slideSpring = useSpring({
//   //   transform: `translateX(-${(currentIndex / visibleCount) * 100}%)`,
//   //   config: { tension: 100, friction: 7 },
//   // });
//   console.log('currentIndex:', currentIndex);
//   console.log('visibleCount:', visibleCount);
//   console.log('transform:', `translateX(-${(currentIndex * 100) / visibleCount}%)`);
  
//   return (
//     <div>
//       <div className="service-container">
//         <div className="wrap">
//           <h1>What We Provide</h1>
//           <div className="arrow-container">
//             <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon-left" onClick={prevSlide} size='2x' />
//             <FontAwesomeIcon icon={faArrowRight} className="arrow-icon-right" onClick={nextSlide} size='2x' />
//           </div>
//         </div>
//         <animated.div className="horizontal-container" style={slideSpring}>
//         {services.slice(currentIndex * visibleCount, (currentIndex + 1) * visibleCount).map((service, index) => (
//           <div onClick={handleServiceClick} className="service1" key={index}>
//             <div className='img-con'>
//               <img src={service.img} className='s-img' alt={service.title} />
//               <img src={service.img1} className='line' />
//             </div>
//             <div className="service-content">
//               <h1>{service.title}</h1>
//               <p>{service.text}</p>
//             </div>
//           </div>
//         ))}
//       </animated.div>
      //   <div className="view-more-container">
      //   {!isExpanded && services.length > 3 && (
      //     <button className="view-more-btn" onClick={showMore}>View More</button>
      //   )}
      //   {isExpanded && (
      //     <button className="view-more-btn" onClick={showLess}>View Less</button>
      //   )}
      // </div>

      // </div>

//       <div className='blue'>
//         <img src={require('../assets/service-blue.png')} className='service-bg' alt="background" />

//         <div className='blue-content'>
//           <h1>Recognize your successes and keep <br />
//              working to accomplish more.</h1>

//           <div className='counters'>

//             <div className='counter-title'>
//               <Counter targetNumber={75} duration={2} prefix="" suffix="+" />
//               <p>Active Clients</p>
//             </div>

//             <div className='counter-title'>
//               <Counter targetNumber={350} duration={2} suffix="+" />
//               <p>Projects Delivered</p>
//             </div>

//             <div className='counter-title'>
//               <Counter targetNumber={90} duration={2} prefix="" suffix="+" />
//               <p>Client Retention</p>
//             </div>

//             <div className='counter-title'>
//               <Counter targetNumber={8} duration={2} prefix="" suffix="+" />
//               <p>Industries Catered</p>
//             </div>

//           </div>

//         </div>

//       </div>
//     </div>
//   );
// }

// export default Services;

import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../styles/Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Counter from './Counter';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);
  
  const services = [
    { img: require('../assets/horiz1.png'), img1: require('../assets/line1.png'), title: 'Logo Design', text: "Empower your brand to communicate creatively and visually. The only tool you need to enhance your brand recognition and identity. With tons of design ideas." },
    { img: require('../assets/horiz2.png'), img1: require('../assets/line1.png'), title: 'Web Design', text: "Sit back, relax and let the professionals build a remarkable and mobile-friendly website that reflects your business values and objectives." },
    { img: require('../assets/horiz3.png'), img1: require('../assets/line1.png'), title: 'Graphic Design', text: "Transform your brand with our expert graphic design services, where creativity meets precision. We create stunning visuals tailored to your vision, ensuring your message shines through brilliantly." },
    { img: require('../assets/horiz4.png'), img1: require('../assets/line1.png'), title: 'Digital Marketing', text: "Social media is a surefire way to generate more traffic. From increased Return on Investment to amplified conversions." },
    { img: require('../assets/horiz5.png'), img1: require('../assets/line1.png'), title: 'Mobile App Design', text: "Design and build phenomenal mobile applications for all types and sizes of smart devices and screens. At IT Envision, we provide all-inclusive mobile app services." },
    { img: require('../assets/horiz6.png'), img1: require('../assets/line1.png'), title: 'UI / UX Design', text: "Elevate your digital presence with our exceptional UI/UX services, designed to create intuitive and engaging user experiences." },
  ];

  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const handleServiceClick = () => {
    navigate('/services');
  };

  const nextSlide = () => {
    if (currentIndex < services.length - 3) {
      setCurrentIndex(currentIndex + 3);
    }
  };

  const prevSlide = () => {
    if (currentIndex >= 3) {
      setCurrentIndex(currentIndex - 3);
    }
  };

  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + 3);
  };

  const slideSpring = useSpring({
    transform: `translateX(-${currentIndex * (100 / 3)}%)`,
    config: { tension: 100, friction: 7 },
  });

  return (
    <div>
      <div className="service-container">
        <div className="wrap">
          <h1>What We Provide</h1>
          {!isMobile && (
            <div className="arrow-container">
              <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon-left" onClick={prevSlide} size='2x' />
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon-right" onClick={nextSlide} size='2x' />
            </div>
          )}
        </div>
        <animated.div className="horizontal-container" style={slideSpring}>
          {services.slice(0, isMobile ? visibleCount : services.length).map((service, index) => (
            <div onClick={handleServiceClick} className="service1" key={index}>
              <div className='img-con'>
                <img src={service.img} className='s-img' alt={service.title} />
                <img src={service.img1} className='line' />
              </div>
              <div className="service-content">
                <h1>{service.title}</h1>
                <p>{service.text}</p>
              </div>
            </div>
          ))}
        </animated.div>
        {isMobile && visibleCount < services.length && (
          <button onClick={handleViewMore} className="view-more-btn">View More</button>
        )}
      </div>

      <div className='blue'>
        <img src={require('../assets/pixels2.jpg')} className='service-bg' alt="background" />
        <div className='blue-content'>
          <h1>Recognize your successes and keep <br /> working to accomplish more.</h1>
          <div className='counters'>
            <div className='counter-title'>
              <Counter targetNumber={75} duration={2} prefix="" suffix="+" />
              <p>Active Clients</p>
            </div>
            <div className='counter-title'>
              <Counter targetNumber={350} duration={2} suffix="+" />
              <p>Projects Delivered</p>
            </div>
            <div className='counter-title'>
              <Counter targetNumber={90} duration={2} prefix="" suffix="+" />
              <p>Client Retention</p>
            </div>
            <div className='counter-title'>
              <Counter targetNumber={8} duration={2} prefix="" suffix="+" />
              <p>Industries Catered</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
